<template>
  <span class="k-input-container">
    <label
      :for="id"
      v-if="label"
      class="k-input-container__label"
      :class="smallLabel ? 'small font-body-small' : 'font-body-normal'"
    >
      {{label}}
    </label>
    <span
      class="k-input"
      :class="inputClasses"
    >
      <span
        v-if="hasPrefix"
        class="k-input__prefix"
        :class="{'k-input__prefix--currency': $attrs.type === 'currency'}"
        ref="prefix"
      >
        <slot name="prefix">
          <span v-if="typeDefaults.prefixString">
            {{typeDefaults.prefixString}}
          </span>
          <i v-else :class="typeDefaults.prefixIcon"></i>
        </slot>
      </span>
      <span
        class="k-input__input-field-wrapper"
        :data-cy="inputWrapperDataCy"
      >
        <slot>
          <div
            v-if="showFormattedValue"
            class="k-input__formatted-value"
            :style="styles" >
            {{formattedValue || value}}
          </div>
          <input
            class="k-input__input-field"
            ref="inputField"
            v-bind="attrsToBind"
            :id="id"
            :value="value"
            v-on="inputListeners"
            :placeholder="placeholder"
            :style="styles"
            :size="size"
            :type="getType()"
            :data-cy="dataCy"
            @keydown="onKeyDown"
            @paste="onPaste"
            />
        </slot>
      </span>
      <span v-if="$slots.suffix" class="k-input__suffix" ref="suffix">
        <slot name="suffix"></slot>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'k-input',

  props: {
    value: {
      type: [String, Number],
    },
    formattedValue: {
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium', 'large'].includes(value),
    },
    label: String,
    dataCy: String,
    smallLabel: Boolean,
  },

  data() {
    return {
      isFocused: false,
      styles: '',
    };
  },

  computed: {
    inputListeners() {
      const vm = this;
      return {
        ...this.$listeners,
        change(event) {
          vm.$emit('change', event.target.value);
          if (vm.$attrs?.min !== undefined) {
            vm.onValidationError(event.target.value < vm.$attrs.min);
          }
        },
        input(event) {
          let { value } = event.target;
          if (vm.$attrs?.min !== undefined && Number(event.target.value) < vm.$attrs.min) {
            value = vm.$attrs.min;
          }
          if (vm.$attrs?.max !== undefined && Number(event.target.value) > vm.$attrs.max) {
            value = vm.$attrs.max;
          }
          vm.$emit('input', value);
        },
        blur(event) {
          vm.isFocused = false;
          vm.$emit('blur', event.target.value);
        },
        focus(event) {
          vm.isFocused = true;
          vm.$emit('focus', event.target.value);
        },
      };
    },

    attrsToBind() {
      const { id, ...rest } = this.$attrs;
      return rest;
    },

    id() {
      // eslint-disable-next-line no-underscore-dangle
      return `input-${this._uid}`;
    },

    inputClasses() {
      return {
        'k-input--error': this.error,
        [`k-input--${this.scale}`]: this.scale !== 'medium',
        'k-input--rounded': this.rounded || this.$attrs.type === 'search',
        'k-input--readonly': this.$attrs.readonly,
      };
    },

    placeholder() {
      return this.$attrs.placeholder || this.typeDefaults.placeholder;
    },

    size() {
      return this.$attrs.size || this.typeDefaults.size;
    },

    showFormattedValue() {
      return !this.isFocused && this.formattedValue;
    },

    typeDefaults() {
      switch (this.$attrs.type) {
        case 'email':
          return {
            prefixIcon: 'far fa-envelope',
          };
        case 'search':
          return {
            prefixIcon: 'far fa-search',
          };
        case 'currency':
          return {
            prefixString: '$',
          };
        case 'tel':
          return {
            prefixIcon: 'far fa-mobile',
            placeholder: '555-555-5555',
            size: 13,
          };
        default:
          return {};
      }
    },

    hasPrefix() {
      return this.$slots.prefix || this.typeDefaults.prefixIcon || this.typeDefaults.prefixString;
    },

    inputWrapperDataCy() {
      if (this.$slots) {
        return this.dataCy;
      }
      return `${this.dataCy}-wrapper`;
    },
  },

  mounted() {
    this.setupStyles();

    if (this.$slots.default) {
      this.setupCustomInput();
    }
  },

  updated() {
    const { prefix, suffix } = this.$refs;
    if (
      (prefix && `${prefix.offsetWidth}px` !== this.styles.paddingLeft)
      || (suffix && `${suffix.offsetWidth}px` !== this.styles.paddingRight)
    ) {
      this.setupStyles();
    }
  },

  methods: {
    onValidationError(error) {
      this.$emit('error', error);
    },

    setupCustomInput() {
      const inputEls = this.$el.getElementsByTagName('input');
      if (inputEls.length) {
        [this.$refs.inputField] = inputEls;
        this.$refs.inputField.classList.add('k-input__input-field');
        Object.keys(this.styles).forEach((key) => {
          this.$refs.inputField.style[key] = this.styles[key];
        });
      }
    },

    setupStyles() {
      const styles = { ...this.$attrs.style };
      if (this.$refs.prefix) {
        styles.paddingLeft = `${this.$refs.prefix.offsetWidth}px`;
      }

      if (this.$refs.suffix) {
        styles.paddingRight = `${this.$refs.suffix.offsetWidth}px`;
      }

      if (this.size) {
        const left = styles.paddingLeft || '12px';
        const right = styles.paddingRight || '12px';
        styles.width = `calc(${this.size}ch + ${left} + ${right})`;
      }

      this.styles = styles;
    },

    getType() {
      if (this.$attrs.type === 'currency') {
        return 'number';
      }
      return this.$attrs.type;
    },
    onKeyDown(event) {
      // type="number" inputs allow e, E, -, + chracters but we would like to disallow
      // those chracters from being entered. please see the following stack overflow link
      // for more info on this edge case:
      // https://stackoverflow.com/a/31706796

      if (this.$attrs.type === 'number') {
        // allow control and combination keys
        const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
        if (controlKeys.includes(event.key) || event.ctrlKey) return;

        const disallowedNumberValues = ['e', 'E', '-', '+'];
        if (disallowedNumberValues.includes(event.key) || Number.isNaN(Number(event.key))) {
          event.preventDefault();
        }
      }
    },
    onPaste(event) {
      if (this.$attrs.type === 'number' && Number.isNaN(Number(event.clipboardData?.getData('text')))) {
        event.preventDefault();
      }
    },

    focusInput() {
      this.$refs.inputField.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
